import { getInfoOfDeviceOS } from '../../assets/js/common-es6';

const getBridge = () => {
  const deviceOS = getInfoOfDeviceOS();

  const wn: any = window;
  if (!wn.__CASHWALK_APP__) wn.__CASHWALK_APP__ = {};

  let bridge = {};

  if (deviceOS === 'ios') {
    bridge = wn?.webkit?.messageHandlers;
  }

  if (deviceOS === 'android') {
    bridge = wn?.__CASHWALK_APP__;
  }

  return {
    bridge,
    deviceOS,
  };
};

const bridgeFunc = (name:string, ...args:any[]) => {
  try {
    const { bridge, deviceOS } = getBridge();

    if (deviceOS === 'ios') {
      return bridge?.[name]?.postMessage(...args);
    }

    if (bridge && bridge[name]) {
      !args.length ? bridge[name]() : bridge[name](...args);
    }
  } catch (e) {
    console.log(e, 'bridge error');
  }
};

export const cashWalkBridgeConfig = {
  firebaseEvent: ({ params }:{params?: Record<string, any>}) => {
    const deviceOS = getInfoOfDeviceOS();

    if (process.env.NODE_ENV === 'development') {
      return console.log('bridgeFunc', params);
    }

    if (deviceOS === 'ios') {
      return bridgeFunc('fireBaseEvent', {
        fireBasePayload: JSON.stringify({
          name: 'trost_event',
          payload: params,
        }),
      });
    }

    if (deviceOS === 'android') {
      bridgeFunc('fireBaseEvent', 'trost_event', JSON.stringify(params));
    }
  },
};
