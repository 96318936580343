export const firebaseEventKey = {
  trost_mindcheck_check_click: ({type}) => ({
    params: {
      event_label: 'trost_mindcheck_check_click',
      type,
    },
  }),
  trost_mindcheck_answer_click: ({type}) => ({
    params: {
      event_label: 'trost_mindcheck_answer_click',
      type,
    },
  }),
  trost_mindcheck_answer_complete: ({type}) => ({
    params: {
      event_label: 'trost_mindcheck_answer_complete',
      type,
    },
  }),
  trost_mindcheck_result_view: ({type}) => ({
    params: {
      event_label: 'trost_mindcheck_result_view',
      type,
    },
  }),
  trost_psytestpage_application_click: ({type}) => ({
    params: {
      event_label: 'trost_psytestpage_application_click',
      type,
    },
  }),
  trost_psytestpage_timeselect_click: ({type}) => ({
    params: {
      event_label: 'trost_psytestpage_timeselect_click',
      type,
    },
  }),
  trost_psytestpage_buy_click: ({type}) => ({
    params: {
      event_label: 'trost_psytestpage_buy_click',
      type,
    },
  }),
  trost_findcenterpage_addresssearch_click: {
    params: {
      event_label: 'trost_findcenterpage_addresssearch_click',
    },
  },
  trost_findcenterpage_address_complete: {
    params: {
      event_label: 'trost_findcenterpage_address_complete',
    },
  },
  trost_findcenterpage_center_click: ({center_id}) => ({
    params: {
      event_label: 'trost_findcenterpage_center_click',
      center_id,
    },
  }),
  trost_findmedicinepage_symptomsearch_complete: {
    params: {
      event_label: 'trost_findmedicinepage_symptomsearch_complete',
    },
  },
  trost_findmedicinepage_pillsearch_complete: {
    params: {
      event_label: 'trost_findmedicinepage_pillsearch_complete',
    },
  },
  trost_findmedicinepage_symptomtype_click: {
    params: {
      event_label: 'trost_findmedicinepage_symptomtype_click',
    },
  },
  trost_findmedicinepage_pilltypesearch_click: {
    params: {
      event_label: 'trost_findmedicinepage_pilltypesearch_click',
    },
  },
  trost_findmedicinepage_dependencebanner_click: {
    params: {
      event_label: 'trost_findmedicinepage_dependencebanner_click',
    },
  },
  trost_checkpage_dependencesurvey_complete: {
    params: {
      event_label: 'trost_checkpage_dependencesurvey_complete',
    },
  },
  trost_mentalcare_storypage_click: ({type}) => ({
    params: {
      event_label: 'trost_mentalcare_storypage_click',
      type,
    },
  }),
  trost_mentalcare_depressiontestpage_click: ({ button, banner }) => {
    const paramValue = button !== undefined ? button : banner;

    return {
      params: {
        event_label: 'trost_mentalcare_depressiontestpage_click',
        [button !== undefined ? 'button' : 'banner']: paramValue,
      },
    };
  },
  trost_mentalcare_selfesteemtestpage_click: ({ button, banner}) => {
    const paramValue = button !== undefined ? button : banner;

    return {
      params: {
        event_label: 'trost_mentalcare_selfesteemtestpage_click',
        [button !== undefined ? 'button' : 'banner']: paramValue,
      },
    };
  },
  trost_mentalcare_mbtitestpage_click: ({ button, banner }) =>{
    const paramValue = button !== undefined ? button : banner;

    return {
      params: {
        event_label: 'trost_mentalcare_mbtitestpage_click',
        [button !== undefined ? 'button' : 'banner']: paramValue,
      },
    };
  },
  trost_myspace_first_click: {
    params: {
      event_label: 'trost_myspace_first_click',
    },
  },
  trost_myspace_situationpage_view: {
    params: {
      event_label: 'trost_myspace_situationpage_view',
    },
  },
  trost_myspace_pickedsound_play: ({sound_id}) => ({
    params: {
      event_label: 'trost_myspace_pickedsound_play',
      sound_id,
    },
  }),
  trost_myspace_notice_click: {
    params: {
      event_label: 'trost_myspace_notice_click',
    },
  },
  trost_myspace_partnershipcertification_click: {
    params: {
      event_label: 'trost_myspace_partnershipcertification_click',
    },
  },
  trost_counselor_page_click: ({id, traffic_source}) => ({
    params: {
      event_label: 'trost_counselor_page_click',
      id,
      traffic_source,
    },
  }),
  trost_counselor_rightnow_click: ({id}) => ({
    params: {
      event_label: 'trost_counselor_rightnow_click',
      id,
    },
  }),
  trost_counselor_reservation_click: ({id}) => ({
    params: {
      event_label: 'trost_counselor_reservation_click',
      id,
    },
  }),
  trost_counselor_text_click: ({id}) => ({
    params: {
      event_label: 'trost_counselor_text_click',
      id,
    },
  }),
  trost_counselor_call_click: ({id}) => ({
    params: {
      event_label: 'trost_counselor_call_click',
      id,
    },
  }),
  trost_counselor_video_click: ({id}) => ({
    params: {
      event_label: 'trost_counselor_video_click',
      id,
    },
  }),
  trost_counselor_meet_click: ({id}) => ({
    params: {
      event_label: 'trost_counselor_meet_click',
      id,
    },
  }),
  trost_counselor_atozbanner_click: {
    params: {
      event_label: 'trost_counselor_atozbanner_click',
    },
  },
  trost_counselor_hearticon_click: ({id}) => ({
    params: {
      event_label: 'trost_counselor_hearticon_click',
      id,
    },
  }),
  trost_soundsubscription_page_view: {
    params: {
      event_label: 'trost_soundsubscription_page_view',
    },
  },
};
